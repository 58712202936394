console.debug(
  'BR2S MFE Shell Internet Build Version:',
  process.env.MFE_BUILD_VERSION,
  '| Build Date:',
  process.env.MFE_BUILD_DATE,
);

/**
 * NOTE: For webpack module federation, remote MFE modules are imported as if they are local files.
 * As a result, the host app requires buffer time to load the remote module before it can be used,
 * which is why a top-level promise in the form of the dynamic "import()" function is required.
 * The app's entry point remains "index.ts", however, the app is loaded asynchronously via "bootstrap".
 */
import('./bootstrap');

export {};
